/*
Template Name: Organista - Organic Shop HTML Template
Author: Androthemes
Author URL: https://themeforest.net/user/androthemes
Version: 1.0.0
*/
/*===========================
 =  Table of Contents
 0. Keyframes
 1. General
 1.1. Typography
 1.2. Section spacings
 1.3. Forms
 1.4. Buttons
 1.5. Colors & Badges
 1.6. Modals, Popups & Dropdowns
 1.7. Tables
 1.8. Tabs
 1.9. Social media links
 2. Header
 2.1. Header style 2
 2.2. Header style 3
 3. Footer
 5. Subheader
 5. Components
 6.. Loaders
 6.1. Blog Posts
 6.1.1. Blog Posts List
 6.1.2. Blog Post Details
 6.2. sidebar
 6.3. Products
 6.3.1. Products (List view)
 6.3.2 Products (Minimal View)
 6.3.3 Products Quick view & details
 6.3.4. Cart & Wishlist
 6.3.5. Checkout
 6.3.6 Products (Recent View)
 6.4. Categories Megamenu
 6.5. Banners
 6.6. Icon Blocks
 6.7. Call To Actions
 6.8.Login & Signup
 6.9. Error 404
 6.10. Testimonials
 6.11. Companies
 6.12. Newsletter Popup
 7. Misc
 8. Responsive
===========================*/
/* Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap");

/*-------------------------------------------------------------------*/
/* Add application styles & imports to this file! */

/* === Keyframes === */

@keyframes BounceUpDown {
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(-5px);
  }
  50%{
    transform: translateY(0);
  }
  75%{
    transform: translateY(-2px);
  }
  100%{
    transform: translateY(0);
  }
}

@keyframes BounceDownUp {
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(5px);
  }
  50%{
    transform: translateY(0);
  }
  75%{
    transform: translateY(2px);
  }
  100%{
    transform: translateY(0);
  }
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}


/*======================
1.0. General
========================*/

.andro_preloader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: .3s;
}
.andro_preloader.hidden{
  opacity: 0;
  visibility: hidden;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #50BD4D;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body,
canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed,
fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup,
html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p,
pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table,
tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video{
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .50;
}

.opacity-75 {
  opacity: .75;
}

.opacity-100 {
  opacity: 1;
}
.dark-overlay{
  position: relative;
  z-index: 1;
}
.dark-overlay::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  z-index: -1;
  -webkit-transition: background-color .3s;
  -o-transition: background-color .3s;
  transition: background-color .3s;
}
.dark-overlay.dark-overlay-2::before{
  background-color: rgba(0,0,0,.6);
}
.andro_sticky-section{
  position: sticky;
  top: 30px;
}

html{
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
  color: #86858c;
  overflow-x: hidden;
}
hr{
  margin: 30px 0;
}
img {
  max-width: 100%;
  height: auto;
}
.imgs-wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.imgs-wrapper img {
  position: absolute;
  max-width: 300px;
}
.imgs-wrapper img:nth-child(1) {
  top: 150px;
  max-width: 400px;
  left: -200px;
}
.imgs-wrapper img:nth-child(2) {
  right: -100px;
  bottom: 0;
}
.andro_single-img-wrapper{
  position: relative;
}
.andro_single-img-wrapper img{
  border-radius: 8px;
}
.andro_dots{
  width: 100%;
  height: 100%;
  content: '';
  background-position: 0 0,6px 6px;
  background-size: 12px 12px;
  position: absolute;
  display: block;
  background-image: -o-radial-gradient(#d1d4d8 1px,transparent 1px),-o-radial-gradient(#d1d4d8 1px,transparent 1px);
  background-image: radial-gradient(#d1d4d8 1px,transparent 1px),radial-gradient(#d1d4d8 1px,transparent 1px);
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
  background-color: transparent;
  top: -17px;
  left: 17px;
  opacity: 1;
  z-index: -1;
}
.signature {
  width: 220px;
  margin-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.close-btn {
  border: 0;
  background-color: transparent;
  padding: 0;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.close-btn:focus {
  outline: none;
}

.close-btn span {
  position: absolute;
  width: 2px;
  height: 15px;
  display: block;
  background-color: #fff;
  opacity: 0.6;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.close-btn.close-dark span {
  background-color: #18181D;
}
.close-btn.close-danger span {
  background-color: red;
}

.close-btn span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.close-btn:hover span {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.close-btn:hover span:nth-child(2) {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.close-btn.dark span {
  background-color: #18181D;
}

.icon-2x {
  font-size: 2rem;
}

.icon-3x {
  font-size: 3rem;
}

.icon-4x {
  font-size: 4rem;
}

.border-0 {
  border: 0;
}

.bg-cover {
  background-size: cover;
}
.bg-parallax{
  background-attachment: fixed;
}
.bg-contain {
  background-size: contain;
}

.bg-norepeat {
  background-repeat: no-repeat;
}

.bg-center {
  background-position: center;
}
.bg-left {
  background-position: left;
}
.bg-right {
  background-position: right;
}
.bg-top {
  background-position: top;
}
.bg-bottom {
  background-position: bottom;
}

/*======================
1.1. Typography
========================*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  line-height: 1.2;
  color: #18181D;
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 42px;
}
h3 {
  font-size: 22px !important;
  line-height: 1.4;
}
h4 {
  font-size: 26px !important;
  line-height: 1.4;
}
h5 {
  font-size: 20px !important;
  line-height: 1.5;
  font-weight: 500;
}
h6 {
  font-size: 16px !important;
  line-height: 1.7;
  font-weight: 500;
}
p {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
  color: #838383;
}
a {
  color: #50BD4D;
  text-decoration: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
a:hover,
a:focus {
  color: #40A13D;
  text-decoration: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
b,
strong{
  font-weight: 700;
}
label{
  margin-bottom: 10px;
  font-weight: normal;
  color: #18181D;
}
ol,
ul {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 20px;
}
ul li,
ol li {
  margin: 10px 0 0;
  position: relative;
}
.andro_list li{
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
}
.andro_list li + li{
  margin-top: 10px;
}
.andro_list li::before{
  font-family: "Font Awesome 5 Free";
  content: "\f055";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1.7;
  font-size: 12px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  margin-right: 10px;
  color: #50BD4D;
}

.small, small{
  line-height: 1.7;
}
blockquote {
  background-color: #fff;
  position: relative;
  padding: 30px;
  margin: 20px 0;
  border-left: 3px solid #50BD49;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

blockquote p{
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}

.entry-content p,
.entry-content img{
  margin-bottom: 20px;
}
.entry-content p:last-child{
  margin-bottom: 0;
}
.entry-content img{
  border-radius: 8px;
}
.entry-content .andro_post-share{
  margin-bottom: 20px;
}

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}

.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.border-25{
  border-radius: 25px;
}

/*======================
1.2 Section Spacings
========================*/
.section {
  position: relative;
  padding: 50px 0;
}
.section.section-padding{
  padding: 50px 0 20px;
}
.andro_section-fw{
  padding: 0 50px;
}
.mb-30{
  margin-bottom: 30px;
}
.mr-lg-30{
  margin-left: 30px;
}
.section.section-padding.category_section{
  margin-top: -50px;
  padding-top: 0;
}
.section-title .title{
  position: relative;
  margin: 0;
  font-size: 18px;
}
.section-title{
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 25px;
  border-bottom: 3px solid #F0F2F3;
}
.section-title::after{
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100px;
  height: 3px;
  background-color: #50BD4D;
}
.section-title.flex-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section-title.flex-title .nav{
  margin-left: auto;
}
.section-title.flex-title .nav-item{
  margin: 0;
}
.section-title.flex-title .nav-item + .nav-item{
  margin-left: 10px;
}
.section-title.flex-title .nav-item .nav-link{
  margin: 0;
}

/*==================
1.3. Forms
====================*/
textarea{
  resize: none;
}
.form-control::-webkit-input-placeholder{
  color: #a5a5a5;
}
.form-control::-moz-placeholder{
  color: #a5a5a5;
}
.form-control:-ms-input-placeholder{
  color: #a5a5a5;
}
.form-control::-ms-input-placeholder{
  color: #a5a5a5;
}
.form-control::placeholder{
  color: #a5a5a5;
}
.form-control {

}
.form-control:focus {
  border: 1px solid #efefef;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-group{
  margin-bottom: 20px;
}
.input-with-icon{
  position: relative;
}
.input-with-icon input,
.input-with-icon select{
  padding-left: 40px;
}
.input-with-icon i{
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 18px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #a5a5a5;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.input-with-icon input:focus + i,
.input-with-icon select:focus + i{
  color: #454545;
}
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #50BD49;
}
.custom-control label{
  line-height: 23px;
}

/*==================
1.4. Buttons
====================*/
.btn-link{
  color: #18181D;
  font-weight: 700;
}
.btn-link:hover{
  color: #40A13D;
}
.andro_btn-custom {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
	background-color: #50BD4D;
  border: 0;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding: 12px 30px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
  text-transform: uppercase;
  border-radius: 25px;
  z-index: 1;
  box-shadow: 0 3px 24px rgba(0,0,0,.1);
}

.andro_btn-custom:hover,
.andro_btn-custom:focus{
  color: #fff;
  background-color: #40A13D;
  outline: none;
}
.andro_btn-custom.primary{
  background-color: #50BD4D;
}
.andro_btn-custom.primary:hover,
.andro_btn-custom.primary:focus{
  background-color: #40A13D;
}
.andro_btn-custom.light{
  background-color: #fff;
  color: #f36523;
}
.andro_btn-custom.light:hover,
.andro_btn-custom.light:focus{
  background-color: #F0F2F3;
}
.andro_btn-custom.secondary{
  background-color: #5d5d5d;
  color: #fff;
}
.andro_btn-custom.secondary:hover,
.andro_btn-custom.secondary:focus{
  background-color: #383838;
}

.andro_btn-custom.btn-sm{
  padding: 12px 16px;
  font-size: 13px;
}
.btn-group .andro_btn-custom:first-child{
  border-radius: 25px 0 0 25px;
}
.btn-group .andro_btn-custom + .andro_btn-custom{
  border-radius: 0 25px 25px 0;
  padding: 12px 14px;
}
.btn-group .andro_btn-custom.btn-sm + .andro_btn-custom.btn-sm{
  padding: 12px 16px;
}
.input-group-append .andro_btn-custom,
.input-group-append .andro_btn-custom::before{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .andro_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after{
  animation-name: BounceUpDown;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}
.btn-group.dropup .andro_btn-custom.dropdown-toggle.dropdown-toggle-split:hover::after{
  animation-name: BounceDownUp;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

/*==================
1.5. Colors & Badges
====================*/
.custom-primary{
  color: #50BD49;
}
.custom-secondary{
  color: #b682e1;
}
.twitter{
  background-color: rgb(44, 170, 225) !important;
  color: #fff;
}
.twitter:hover{
  background-color: #2caae1;
  color: #fff;
}

.facebook {
  background-color: rgb(59, 87, 157) !important;
  color: #fff;
}
.facebook:hover{
  background-color: #3b579d;
  color: #fff;
}

.instagram {
  background-color: rgb(193, 53, 132) !important;
  color: #fff;
}
.instagram:hover{
  background-color: #C13584;
  color: #fff;
}

.google {
  background-color: rgb(220, 74, 56) !important;
  color: #fff;
}
.google:hover{
  background-color: #dc4a38;
  color: #fff;
}

.linkedin{
  background-color: rgb(1, 119, 181) !important;
  color: #fff;
}
.linkedin:hover{
  background-color: #0177b5;
  color: #fff;
}

.pinterest {
  background-color: rgb(204, 33, 39) !important;
  color: #fff;
}
.pinterest:hover{
  background-color: #cc2127;
  color: #fff;
}

.youtube{
  background-color: rgb(229, 45, 39);
  color: #fff;
}
.youtube:hover{
  background-color: #e52d27;
  color: #fff;
}

.github {
  background-color: rgb(51, 51, 51) !important;
  color: #fff;
}
.github:hover{
  background-color: #333333 !important;
  color: #fff;
}

.behance {
  background-color: rgb(23, 105, 255) !important;
  color: #fff;
}
.behance:hover{
  background-color: #1769ff;
  color: #fff;
}

.dribbble {
  background-color: rgb(234, 76, 137) !important;
  color: #fff;
}
.dribbble:hover{
  background-color: #ea4c89;
  color: #fff;
}

.reddit{
  background-color: rgb(255, 69, 0) !important;
  color: #fff;
}
.reddit:hover{
  background-color: #ff4500;
  color: #fff;
}

.light-bg{
  background-color: #F0F2F3;
}
.primary-bg{
  background-color: #50BD4D;
}
.secondary-bg{
  background-color: #5d5d5d;
}
.pattern-bg{
  position: relative;
  z-index: 1;
}
.pattern-bg::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/3.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.pattern-bgpp{
  position: relative;
  z-index: 1;
}
.pattern-bgpp::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/4.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.pattern-bgrp::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/6.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.pattern-bgrp{
  position: relative;
  z-index: 1;
}
.pattern-bgrp::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/6.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.pattern-bgrp::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/6.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.pattern-bgcu{
  position: relative;
  z-index: 1;
}
.pattern-bgcu::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/9.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.pattern-bgtc{
  position: relative;
  z-index: 1;
}
.pattern-bgtc::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/8.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.pattern-bgdp{
  position: relative;
  z-index: 1;
}
.pattern-bgdp::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/7.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.pattern-bgdp::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/7.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.pattern-bg2::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/banner/1.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

/*=============================
1.6 Modal, Popups & Dropdowns
==============================*/

.modal-header{
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
/* .modal-header .close:not(:disabled):not(.disabled):hover{
  background-color: #dd3333;
  opacity: 1;
} */
.modal-lg{
  max-width: 1000px;
}
.modal-content{
  border-radius: 8px;
  border: 0;
}

.dropdown-menu{
  padding: 10px;
  font-size: 14px;
  color: #838383;
  border: 1px solid #F0F2F3;
  box-shadow: 0 4px 24px rgba(0,0,0,.1);
}
.dropdown-item{
  padding: 6px 10px;
  font-weight: 600;
  color: #838383;
  border-radius: 8px;
}
.dropdown-item i{
  display: inline-block;
  width: 25px;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: #f8f9fa;
  color: #16181b;
}

/*======================
1.7 Tables
========================*/
table th,
table td {
  padding: 15px;
  vertical-align: middle;
  background-color: #fff;
}
table th{
  font-weight: 600;
  color: #18181D;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #F0F2F3;
}
table {
  width: 100%;
  margin-bottom: 30px;
}
table td{
  border: 1px solid #F0F2F3;
}
table img{
  width: 40px;
  border-radius: 0;
}

/*======================
1.8 Tabs
========================*/
.tab-content{
  margin-top: 20px;
}
.nav-item{
  margin: 0;
}
.nav-link{
  padding: 10px 20px;
  background-color: #F0F2F3;
  font-weight: 600;
  margin-right: 10px;
  border-radius: 0;
  color: #18181D;
  border-radius: 8px;
}
.nav-link.active{
  background-color: #50BD49;
  color: #fff;
}
.tab-content>.tab-pane{
  display: block;
  height: 0;
}
.tab-content>.active{
  height: auto;
}

/*======================
1.9. Social media links
========================*/
.andro_sm{
  display: flex;
  align-items: center;
  margin: 0;
}
.andro_sm li{
  margin: 0;
}
.andro_sm li a{
  display: inline-block;
}
.andro_sm li + li{
  margin-left: 20px;
}

/*======================
2. Header
========================*/

.andro_header.sticky.header-1 .andro_header-bottom,
.andro_header.header-3.sticky .andro_header-bottom{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 190;
}

.andro_header.header-2.sticky .andro_header-middle{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 190;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border-bottom: 1px solid #F0F2F3;
}

.andro_header-top{
  background-color: #5d5d5dbf;
  padding: 0;
}
.andro_header-top-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.andro_header-top-links{
  display: flex;
  align-items: center;
  margin: 0;
}
.andro_header-top-links li{
  margin: 0;
}
.andro_header-top-links > li + li{
  margin-left: 15px
}
.andro_header-top-sm li a,
.andro_header-top-links a{
  color: #fff;
}
.andro_header-top-sm li a:hover,
.andro_header-top-links a:hover{
  color: #50BD4D;
}
.andro_header-top-links a{
  font-weight: 600;
  font-size: 13px;
  padding: 10px 0;
  display: block;
}
.andro_header-middle{
  background-color: #fff;
}
.andro_header-middle .navbar{
  padding: 15px 0;
}
.navbar-brand{
  padding: 0;
    width: 190px;
    float: left;
}
.logo-details {
  float: left;
  width: 330px;
}
.andro_header-controls{
  display: flex;
  align-items: center;
}
.andro_header-controls-inner{
  display: flex;
  align-items: center;
  margin: 0;
}
.andro_header-controls-inner li{
  margin: 0;
}
.andro_header-controls-inner li a{
  width: 58px;
  height: 58px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F0F2F3;
  margin: 0 10px 0 0;
}
.andro_header-controls-inner li.notifications{
  position: relative;
}
.andro_header-controls-inner li.notifications a {
  border: none;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
}
.andro_header-controls-inner li.notifications .not_count {
 position: absolute;
 z-index: 999;
 background: green;
 font-size: .5rem;
 display: inline-block;
 width: 13px;
 height: 13px;
 line-height: 12px;
 border-radius: 10px;
 top: 0px;
 right: 10px;
 color: #fff;
 text-align: center;
}
.andro_header-controls-inner li.notifications a i {
  color: #f36523
}
.andro_header-controls-inner li.notifications ul{
      position: absolute;
      right: 0;
      top: 50px;
      width: 200px;
      height: auto;
      padding: 1rem;
      border-top: 1px solid green;
      background: #fff;
          z-index: 99;
    box-shadow: 0px 4px 10px darkgreen;
        max-height: 300px;
        overflow-y: auto;
}
.andro_header-controls-inner li.notifications ul li{
  text-align:left;
  border-bottom: 1px dotted green;
}
.andro_header-controls-inner li.notifications ul li a{
  width:auto;
  justify-content: flex-start; font-size:.9rem;
}

.andro_header-controls-inner li:last-child a{
  margin-right: 0;
}
.andro_header-controls-inner li.andro_header-cart a{
  width: auto;
  height: auto;
  border-radius:4px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
}
.andro_header-controls-inner .links > a{width:auto; height: auto; border-radius:4px; font-weight:500; padding:8px 15px; min-width:65px; display: flex;align-items: center; background: #088141; color: #fff;}
.andro_header-controls-inner .links > a i{color:#fff;}
.andro_header-controls-inner li.submenu{position: relative;}
.andro_header-controls-inner li.submenu > a{position: relative;}
.andro_header-controls-inner li.submenu > a:after{content:"\f078"; font-family: fontAwesome; font-size: 12px; width: 10px; position: absolute; right: 0;}
.andro_header-controls-inner li.submenu ul{position: absolute;background:rgba(8,129,65,1); z-index:-1;
  width: 170px;right: 0;padding: 10px;border-radius: 2px;top:130%; transition:.5s ease; opacity:0;}
.andro_header-controls-inner li.submenu ul:before{content:"";    border-left: 10px solid transparent;
  border-right: 10px solid transparent; border-bottom: 10px solid #088141; position: absolute; right: 10px;top: -6px;}
.andro_header-controls-inner li.submenu li{width:100%}
.andro_header-controls-inner li.submenu ul a{text-decoration: none;display: block;color: #04ff7a;}
.andro_header-controls-inner li.submenu ul a i{font-size:15px; color:inherit;}
.andro_header-controls-inner li.submenu.active ul{opacity:1; top:100%; z-index:99;}
.andro_header-controls-inner li.submenu li:hover a{color:#fff; background-color:#ed7d23; border-radius:2px;}
.andro_header-controls-inner .links a:hover{background-color: #ed7d23;}

.andro_header-bottom{
  background-color: #5D5D5D;
}
.andro_header-bottom-inner{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.andro_header-bottom-inner .navbar-nav,
.andro_header-middle .navbar-nav{
  display: flex;
  align-items: center;
  flex-direction: row;
}
.andro_header-bottom-inner .navbar-nav .menu-item,
.andro_header-middle .navbar-nav .menu-item{
  margin: 0;
}
.andro_header-bottom-inner > .navbar-nav > .menu-item > a,
.andro_header-middle .navbar > .navbar-nav > .menu-item > a{
  position: relative;
  padding: 20px;
  color: #fff;
  font-weight: 600;
  display: block;
}
.andro_header-middle .navbar > .navbar-nav > .menu-item > a{
  color: #18181D;
  padding: 30px 20px;
}
.andro_header-bottom-inner > .navbar-nav > .menu-item > a:hover,
.andro_header-middle .navbar > .navbar-nav > .menu-item > a:hover{
  color: #50BD4D;
}
.andro_header-bottom-inner > .navbar-nav > .menu-item:first-child > a,
.andro_header-middle .navbar > .navbar-nav > .menu-item:first-child > a{
  padding-left: 0;
}

.andro_header-bottom-inner .navbar-nav .menu-item-has-children,
.andro_header-middle .navbar-nav .menu-item-has-children{
  position: relative;
}

.andro_header .sub-menu,
.andro_flex-menu .sub-menu{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: .3s;
  opacity: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-75deg);
  transform: rotateX(-75deg);
  transform-origin: 0% 0%;
  visibility: hidden;
  z-index: 998;
  min-width: 230px;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.andro_header .sub-menu.sub-menu-left,
.andro_flex-menu .sub-menu.sub-menu-left{
  left: auto;
  right: 0;
}
.andro_header-middle .navbar-nav .menu-item-has-children:hover > .sub-menu,
.andro_header-bottom-inner .navbar-nav .menu-item-has-children:hover > .sub-menu,
.andro_search-adv-cats:hover .sub-menu,
.andro_header-top-links .menu-item-has-children:hover .sub-menu,
.andro_flex-menu .menu-item-has-children:hover .sub-menu{
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}
.andro_header .sub-menu a,
.andro_flex-menu .sub-menu a{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  color: #838383;
  font-weight: 400;
  border-bottom: 1px solid #F0F2F3;
}
.andro_header .sub-menu a:hover,
.andro_flex-menu .sub-menu a:hover{
  background-color: #F0F2F3;
}
.andro_header .sub-menu .sub-menu,
.andro_flex-menu .sub-menu .sub-menu{
  top: 0;
  left: 100%;
}
.andro_header .sub-menu .menu-item-has-children > a::after,
.andro_flex-menu .sub-menu .menu-item-has-children > a::after{
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1.7;
  margin-left: auto;
  font-size: 10px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  color: #838383;
}

.andro_search-adv form{
  display: flex;
}
.andro_search-adv-cats{
  position: relative;
  background-color: #F0F2F3;
  border-radius: 25px 0 0 25px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.andro_search-adv-cats label{
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #838383;
  font-weight: 400;
  border-bottom: 1px solid #F0F2F3;
  cursor: pointer;
  margin: 0;
  transition: .3s;
}
.andro_header .sub-menu label:hover{
  background-color: #F0F2F3;
}
.andro_search-adv-cats label input{
  position: absolute;
  opacity: 0;
  visibility: hidden
}
.andro_search-adv-cats label input + i{
  color: #30ca71;
  transition: .3s;
  visibility: hidden;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  font-size: 12px;
}
.andro_search-adv-cats label input:checked + i{
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.andro_search-adv-cats > span{
  color: #18181D;
  font-size: 13px;
  font-weight: 600;
}
.andro_search-adv-input{position: relative;}
.andro_search-adv-input .clear{position: absolute; right:10px; top:10px; font-size:.95rem; display:none; cursor: pointer; width:12px; height:12px; background-color:#ff4500;}
.andro_search-adv-input .form-control{
  border-radius:0; border: 1px solid #ced4da;
}
.andro_search-adv-input .form-control:focus +  .clear{display:block;}
.andro_search-adv-input .form-control::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button; z-index:99;
}

.andro_search-adv-input .form-control {
    -webkit-appearance: none;
}
.sidebar-widget .andro_search-adv-input{
  width: 100%;
}
.sidebar-widget .custom-control-label{
  font-weight: 400;
}
.sidebar-widget .andro_search-adv-input .form-control{
  border-radius: 25px;
}
.sidebar-widget.widget-search{
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.andro_search-adv-input{
  position: relative;
  width: 350px;
}
.andro_search-adv-input button{
  position: absolute;
  top: 50%;
  right:-50px;
  transform: translateY(-50%);
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 38px;
  background-color: #dee2e6;
  border-radius: 0 25px 25px 0;
  color: #838383;
  transition: .3s;
  border-left: 1px solid #F0F2F3;
}
.andro_search-adv-input button:hover{
  background-color: #F0F2F3;
}

.andro_search-adv-cats .sub-menu li,
.andro_search-adv-cats .sub-menu{
  margin: 0;
}
/*prashant*/
.andro_search-adv1 form{
  display: flex;
}
.andro_search-adv1-cats{
  position: relative;
  background-color: #F0F2F3;
  border-radius: 25px 0 0 25px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.andro_search-adv1-cats label{
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #838383;
  font-weight: 400;
  border-bottom: 1px solid #F0F2F3;
  cursor: pointer;
  margin: 0;
  transition: .3s;
}
/* .andro_header .sub-menu label:hover{
  background-color: #F0F2F3;
} */
.andro_search-adv1-cats label input{
  position: absolute;
  opacity: 0;
  visibility: hidden
}
.andro_search-adv1-cats label input + i{
  color: #30ca71;
  transition: .3s;
  visibility: hidden;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  font-size: 12px;
}
.andro_search-adv1-cats label input:checked + i{
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.andro_search-adv1-cats > span{
  color: #18181D;
  font-size: 13px;
  font-weight: 600;
}
.andro_search-adv1-input .form-control{
  border-radius: 0 25px 25px 0;
}
.sidebar-widget .andro_search-adv1-input{
  width: 100%;
}
/* .sidebar-widget .custom-control-label{
  font-weight: 400;
} */
.sidebar-widget .andro_search-adv1-input .form-control{
  border-radius: 25px;
}
/* .sidebar-widget.widget-search{
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
} */
.andro_search-adv1-input{
  position: relative;
  width: 400px;
}
.andro_search-adv1-input button{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 36px;
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #838383;
  transition: .3s;
  border-left: 1px solid #F0F2F3;
}
.andro_search-adv1-input button:hover{
  background-color: #F0F2F3;
}

.andro_search-adv1-cats .sub-menu li,
.andro_search-adv1-cats .sub-menu{
  margin: 0;
}

/* Mega Menu */
.navbar-nav .menu-item.mega-menu-wrapper{
  position: static;
}
.navbar-nav .menu-item.mega-menu-wrapper .sub-menu{
  width: 100%;
}
.navbar-nav .menu-item.mega-menu-wrapper .sub-menu li{
  margin: 0;
  padding: 20px;
}
.mega-menu-promotion-wrapper{
  margin-top: 30px;
}
.navbar-nav .mega-menu-promotion{
  text-align: center;
}
.navbar-nav .mega-menu-promotion > a{
  display: block;
  padding: 0;
}
.navbar-nav .mega-menu-promotion img{
  width: 150px;
  margin-bottom: 5px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.navbar-nav .mega-menu-promotion:hover img{
  -webkit-transform: translateY(-4px);
      -ms-transform: translateY(-4px);
          transform: translateY(-4px);
}
.mega-menu-promotion-text h4{
  margin-bottom: 5px;
  font-size: 18px;
}
.mega-menu-promotion-text span{
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #848486;
}
.navbar-nav .mega-menu-promotion-text h4 a{
  display: block;
  padding: 0;
  color: #18181D;
}
.navbar-nav .mega-menu-promotion-text h4 a:hover{
  color: #ED4E53;
}
.navbar-nav .mega-menu-item h6{
  margin-bottom: 10px;
  font-weight: 700;
}
.navbar-nav .mega-menu-item p{
  padding: 10px 0;
}
.navbar-nav .mega-menu-item a{
  display: block;
  font-weight: 400;
  padding: 10px 0;
}
.navbar-nav .mega-menu-item a:last-child{
  border-bottom: 0;
}
.navbar-nav .mega-menu-item a:hover{
  background-color: transparent;
  color: #50BD4D;
}
.navbar-nav .mega-menu-item .andro_btn-custom{
  display: inline-block;
  padding: 12px 30px;
  color: #fff;
  font-weight: 600;
}
.navbar-nav .mega-menu-item .andro_btn-custom:hover{
  color: #fff;
  background-color: #40A13D;
}
.navbar-nav .mega-menu-item a.coming-soon{
  color: #b7b7b7;
  cursor: default;
}
.navbar-nav .mega-menu-item a.coming-soon span{
  margin-left: 5px;
  font-size: 12px;
}

/* Mobile sidenav */
.aside-toggler{
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
}
.aside-toggler.desktop-toggler{
  display: flex;
  height: 65px;
  width: 65px;
  align-items: center;
  justify-content: center;
  background-color: #f36523;
  transition: .3s;
}
.aside-toggler.desktop-toggler span{
  background-color: #fff;
}
.aside-toggler.desktop-toggler:hover{
  background-color: #40A13D;
}
.aside-toggler span {
  display: block;
  margin-bottom: 4px;
  width: 22px;
  height: 3px;
  border-radius: 3px;
  background-color: #434344;
  transition: .3s; opacity:1;
  transform-origin: center;
}
.aside-toggler span:nth-child(3){
  margin-bottom: 0;
}
.andro_aside{
  position: fixed;
  top: 0;
  left: 0;
  width: 245px;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  transition: .3s;
  overflow: auto;
  border-right: 1px solid #ccc;
  box-shadow:2px 0 15px #555;
}
.andro_aside-overlay.aside-trigger-left,
.andro_aside.andro_aside-left{
  display: none;
}
.andro_aside.andro_aside-right{
  left: auto;
  right: -400px;
  padding: 30px;
  width: 400px;
}
.andro_aside.andro_aside-right::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../img/products/14.png'); */
  opacity: .3;
  z-index: -1;
  background-position: 0 130%;
  background-repeat: no-repeat;

}
.andro_aside .logo{display: block;clear: both;padding:0;}
.andro_aside .logo img{max-width:100px;}
.andro_aside.andro_aside-right .sidebar{
  display: none;
}
.andro_aside.open{
  left: 0;
}
.andro_aside.andro_aside-right.open{
  left: auto;
  right: 0;
}
.andro_aside.open + .andro_aside-overlay{
  opacity: 1;
  visibility: visible;
}
.andro_aside-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background-color: rgba(0,0,0,.7);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
.andro_aside ul{
  margin: 0;
}
.andro_aside ul .menu-item {
  margin: 0;
}
.andro_aside ul .menu-item a{
  padding:10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #18181D;
  font-weight: 700;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.andro_aside ul .menu-item a:hover,
.andro_aside ul .menu-item a.active{
  color: #50BD4D;
}
.andro_aside ul .menu-item.menu-item-has-children>a::after {
  font-family: "Font Awesome 5 Free";
  /* content: "\f078"; */
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1.7;
  margin-left: auto;
  font-size: 10px;
}
.andro_aside ul .menu-item .sub-menu{
  display: none;
  background-color: #F0F2F3;
}
.andro_aside .navbar-brand{
  padding: 10px 15px;
  display: block;
  width: 200px;
  margin: 0;
}

.andro_aside.andro_aside-right .sidebar-widget .widget-title{
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
  font-size: 18px;
}
.andro_aside.andro_aside-right .sidebar-widget.widget-categories-icons + .sidebar-widget{
  margin-top: 0;
}
.andro_aside.andro_aside-right .sidebar-widget .widget-title::after{
  display: none;
}


.aside-toggler.active span:nth-child(1){transform: rotate(45deg) translate(2px, 1px); margin: 0;}
.aside-toggler.active span:nth-child(2){opacity:0; margin: 0;}
.aside-toggler.active span:nth-child(3){transform:rotate(-45deg) translate(3px, -3px);margin: 0;}
.aside-toggler.active span:nth-child(1){transform: rotate(45deg) translate(2px, 1px); margin: 0;}


/* 2.1. Header Style 2 */
.andro_header.header-2 .andro_header-middle .navbar{
  padding: 0;
}
.andro_header.header-2 .andro_header-bottom-inner .andro_search-adv{
  height: 65px;
}
.andro_header.header-2 .andro_header-bottom-inner .andro_search-adv form,
.andro_header.header-2 .andro_search-adv-input .form-control,
.andro_header.header-2 .andro_search-adv-input button,
.andro_header.header-2 .andro_search-adv-cats{
  height: 100%;
  border-radius: 0;
}
.andro_header.header-2 .andro_header-bottom{
  background-color: #fff;
  border-top: 1px solid #F0F2F3;
}

/* 2.2. Header Style 3 */
.andro_header.header-3 .andro_header-bottom-inner .navbar-nav{
  margin-right: auto;
}
.andro_header.header-3 .andro_header-bottom-inner > .navbar-nav > .menu-item:first-child > a{
  padding: 12px;
}
.andro_header.header-3 .andro_category-mm{
  height: 50px;
  width: 250px;
}
.andro_header.header-3 .andro_category-mm .andro_category-mm-header{
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  transition: .3s;
}
.andro_header.header-3 .andro_category-mm .andro_category-mm-header:hover{
  background-color: #40A13D;
}
.andro_header.header-3 .andro_category-mm .andro_category-mm-header h6{
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.andro_header.header-3 .andro_category-mm .andro_category-mm-header h6::after{
  color: #f1f1f1;
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1.7;
  margin-left: auto;
  font-size: 10px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.andro_header.header-3 .andro_category-mm-body{
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  z-index: 99;
  min-width: 250px;
  opacity: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-75deg);
  transform: rotateX(-75deg);
  transform-origin: 0% 0%;
  visibility: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.andro_header.header-3 .andro_category-mm.open .andro_category-mm-body{
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}
.andro_header.header-3 .andro_category-mm.open .andro_category-mm-header h6::after{
  transform: rotate(180deg);
}
/*======================
3. Footer
========================*/
.andro_footer {
  background-color: #F0F2F3;
  padding: 50px 0 0;
}
.andro_footer p{
  margin-bottom: 20px;
  color: #515151;
}
.andro_footer-top{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 50px;
}
.andro_footer-middle a,
.andro_footer-middle i{
  color: #515151;
}
.andro_footer-middle a:hover{
  color: #50BD4D;
}
.andro_footer-middle a.andro_btn-custom,
.andro_footer-middle a.andro_btn-custom:hover{
  color: #fff;
}
.andro_footer-bottom ul{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0 20px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  margin-bottom: 20px;
}
.andro_footer-bottom ul li{
  margin: 0;
}
.andro_footer-bottom ul li a{
  padding-right: 20px;
  color: #515151;
}
.andro_footer-bottom ul li a:hover{
  color: #50BD4D;
}
.andro_footer-bottom .andro_footer-copyright{
  padding-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.andro_footer-bottom .andro_footer-copyright p{
  margin: 0;
}
.andro_footer-bottom .andro_footer-copyright > a{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #18181D;
  font-size: 14px;
  font-weight: 600;
}
.andro_footer-bottom .andro_footer-copyright > a:hover{
  color: #50BD4D;
}
.andro_footer-bottom .andro_footer-copyright > a i{
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #4e4e4e;
  color: #fff;
  margin-left: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.andro_footer-bottom .andro_footer-copyright > a:hover i{
  background-color: #50BD4D;
  color: #fff;
}
.footer-widget {
  padding-bottom: 50px;
}
.andro_footer-buttons a{
  display: inline-block;
  width: 150px;
}
.footer-widget .widget-title{
  margin-bottom: 20px;
  font-weight: 700;
}
.andro_footer-logo{
  width: 180px;
}
.footer-widget ul {
  margin: 0;
}
.footer-widget ul li + li{
  margin: 10px 0 0;
}
.footer-widget ul li a {
  position: relative;
  display: block;
  -webkit-transform: translateX(-15px);
      -ms-transform: translateX(-15px);
          transform: translateX(-15px);
          color:#515151 !important;
}
.footer-widget ul li a::before{
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1.7;
  margin-left: auto;
  font-size: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
          transform: translateX(-10px);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  color: #50BD4D;
  padding-right: 10px;
}
.footer-widget ul li a i{
  font-weight: 400;
}
.footer-widget ul li a:hover{
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}
.footer-widget ul li a:hover::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}

.footer-widget ul.social-media li {
  display: inline-block;
  margin-top: 0;
}
.footer-widget ul.social-media li a{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  font-size: 18px;
  border: 0;
  color: #fff;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.footer-widget ul.social-media li a::before{
  display: none;
}
.footer-widget ul.social-media li a i{
  color: #fff;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.footer-widget ul.social-media li a:hover i{
  color: #fff;
}
.andro_footer-offer{
  margin-top: 15px;
}
.andro_footer-offer p{
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 15px;
}
.andro_footer-offer .btn-custom,
.andro_footer-offer .btn-custom:hover{
  color: #fff;
}
.andro_instagram{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}
.andro_ig-item{
  display: block;
  overflow: hidden;
}
.andro_ig-item:hover img{
  transform: scale(1.1);
}
.andro_ig-item img{
  transition: .3s;
}

/* Dark Footer */
.andro_footer.andro_footer-dark{
  background-color: #18181D;
}
.andro_footer.andro_footer-dark p,
.andro_footer.andro_footer-dark .footer-widget ul li a::before,
.andro_footer.andro_footer-dark .andro_footer-bottom ul li a:hover,
.andro_footer.andro_footer-dark .footer-widget ul li a:hover,
.andro_footer.andro_footer-dark .andro_footer-bottom .andro_footer-copyright > a,
.andro_footer.andro_footer-dark .footer-widget .widget-title{
  color: #fff;
}

.andro_footer.andro_footer-dark .andro_footer-bottom ul li a,
.andro_footer.andro_footer-dark .footer-widget ul li a{
  color: #b7b7b7;
}
.andro_footer.andro_footer-dark .andro_footer-bottom ul{
  border-bottom: 1px solid rgba(255,255,255,.1);
}

/*======================
5. Subheader
========================*/
.andro_subheader{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.andro_subheader-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0;
}
.andro_subheader h1{
  font-size: 30px;
  line-height: 1.5;
  margin: 0;
  color: #fff;
}
.andro_subheader .breadcrumb{
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.andro_subheader .breadcrumb li{
  margin: 0;
  color: #f1f1f1;
}
.andro_subheader .breadcrumb li a{
  color: #f1f1f1;
  font-weight: 700;
}
.andro_subheader .breadcrumb li a:hover{
  color: #fff;
}
.andro_subheader .breadcrumb .breadcrumb-item.active{
  color: #fff;
}
.andro_subheader .breadcrumb-item+.breadcrumb-item::before{
  color: #f1f1f1;
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1.7;
  margin-left: auto;
  font-size: 10px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

/*======================
6. Components
========================*/

/* 6.1. Blog Posts */
.andro_post{
  position: relative;
  margin-bottom: 30px;
}
.andro_post .andro_post-thumb a,
.gallery-thumb{
  position: relative;
  display: block;
  overflow: hidden;
}
.andro_post .andro_post-thumb a::before,
.gallery-thumb::before{
  content: '';
  position: absolute;
  left: 0;
  top: -50%;
  width: 10%;
  height: 190%;
  background-color: rgba(255,255,255,.2);
  transition: .3s ease-in;
  opacity: 0;
  visibility: hidden;
  transform: rotate(45deg);
}

.andro_post .andro_post-thumb a:hover::before,
.gallery-thumb:hover::before{
  opacity: 1;
  visibility: visible;
  left: 190%;
}
.andro_post .andro_post-thumb a,
.andro_post .andro_post-thumb a img{
  border-radius: 8px 8px 0 0;
}
.andro_post .andro_post-categories{
  margin: 0 0 17px;
}
.andro_post .andro_post-categories a{
  background-color: rgba(80, 189, 77, .25);
  display: inline-block;
  padding: 5px 8px;
  color: #50BD4D;
  font-size: 13px;
  line-height: 1.7;
  border-radius: 8px;
  font-weight: 600;
  margin: 0 3px 3px 0;
}
.andro_post .andro_post-categories a:hover{
  background-color: #50BD4D;
  color: #fff;
}
.andro_post .andro_post-body{
  padding: 20px;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border-radius: 0 0 8px 8px;
}
.andro_post .andro_post-body h5{
  margin: 0 0 15px;
  word-break: break-word;
}
.andro_post .andro_post-body h5 a{
  color: #18181D;
}
.andro_post .andro_post-body h5 a:hover,
.andro_post .andro_post-body .andro_post-desc > span a:hover{
  color: #40A13D;
}
.andro_post .andro_post-body .andro_post-desc > span{
  display: block;
  color: #18181D;
  font-size: 13px;
  line-height: 1.7;
  margin-bottom: 15px;
}
.andro_post .andro_post-body .andro_post-desc > span a{
  color: #18181D;
  text-decoration: underline;
}
.andro_post .andro_post-body .andro_post-desc p{
  margin-bottom: 20px;
}

/* 6.1.1. Blog Posts list */
.andro_post.andro_post-list{
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border-radius: 8px;
}
.andro_post.andro_post-list .andro_post-body{
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
  padding: 0 20px;
}
.andro_post.andro_post-list .andro_post-body,
.andro_post.andro_post-list .andro_post-thumb{
  flex: 1;
}
.andro_post.andro_post-list .andro_post-thumb a,
.andro_post.andro_post-list .andro_post-thumb a img{
  border-radius: 8px 0 0 8px;
}

/* 6.1.2. Blog Post Details */
.comment-form{
  margin-bottom: 50px;
}
.comments-list ul{
  margin: 0;
}
.comments-list .comment-item{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.comments-list .comment-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.comments-list .comment-item img {
  width: 65px;
  margin-right: 20px;
  border-radius: 50%;
}
.comments-list .comment-item .comment-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.comments-list .comment-item h5 {
  margin-bottom: 15px;
  font-size: 18px;
}
.comments-list .comment-item span {
  display: inline-block;
  margin-bottom: 15px;
  color: #848486;
  font-size: 13px;
  line-height: 21px;
  position: absolute;
  top: 4px;
  right: 0;
}
.comments-list .comment-item p {
  margin-bottom: 15px;
}
.reply-link {
  position: relative;
  color: #18181D;
  font-weight: 600;
}

.andro_post-single .andro_post-share{
  margin-top: 50px;
}
.andro_post-single-thumb img{
  margin-bottom: 30px;
}
.andro_post-single-author{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.andro_post-single-author img{
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 0;
}
.andro_post-single-author-content p{
  font-weight: 600;
  color: #18181D;
  margin-bottom: 0;
}
.andro_post-single-author-content span{
  font-size: 13px;
}
.andro_post-single-meta{
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin-top: 50px;
  border-top: 1px solid rgba(0,0,0,.1);
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.andro_post-single-meta-item{
  flex: 1;
}
.andro_post-share .andro_sm li + li{
  margin-left: 10px;
}
.andro_post-share .andro_sm li a{
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F0F2F3;
  background-color: #fff;
}
.andro_post-share .andro_sm li a i{
  color: #838383;
}
.andro_post-share .andro_sm li a:hover{
  background-color: #F0F2F3;
}
.andro_single-pagination{
  margin: 30px 0;
}
.andro_single-pagination-item + .andro_single-pagination-item{
  margin-top: 10px;
}
.andro_single-pagination-thumb{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F0F2F3;
  background-color: #fff;
  border-radius: 15px;
  margin-right: 10px;
}
.andro_single-pagination a{
  display: flex;
  align-items: center;
}
.andro_single-pagination a h6{
  margin: 0;
  font-size: 14px;
}
.andro_single-pagination a img{
  max-width: 40px;
}
.andro_single-pagination-prev,
.andro_single-pagination-next{
  padding: 10px;
  border-radius: 25px;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  transition: .3s;
}
.andro_single-pagination-prev:hover,
.andro_single-pagination-next:hover{
  background-color: #F0F2F3;
}
.andro_single-pagination i{
  color: #838383;
  margin-left: auto;
  margin-right: 20px;
  transition: .3s;
}
.andro_single-pagination-prev:hover i{
  transform: translateX(-3px);
}
.andro_single-pagination-next:hover i{
  transform: translateX(3px);
}

/* 6.2. Sidebar */
.sidebar-widget + .sidebar-widget{
  margin-top: 30px;
}
.sidebar-widget .widget-title{
  position: relative;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 25px;
  border-bottom: 3px solid #F0F2F3;
}
.sidebar-widget .widget-title::after{
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100px;
  height: 3px;
  background-color: #50BD4D;
}

.sidebar-widget ul.sidebar-widget-list{
  margin: 0;
  padding: 0;
}
.sidebar-widget ul.sidebar-widget-list ul{
  padding-left: 20px;
}
.sidebar-widget ul.sidebar-widget-list li{
  margin: 0;
}
.sidebar-widget ul.sidebar-widget-list li ul li:first-child{
  margin-top: 20px;
}
.sidebar-widget ul.sidebar-widget-list li + li{
  margin-top: 20px;
}
.sidebar-widget ul.sidebar-widget-list li a{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #838383;
}
.sidebar-widget ul.sidebar-widget-list li a:hover{
  color: #50BD4D;
}
.widget-about-author{
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border: 1px solid #F0F2F3;
  border-radius: 8px;
}
.widget-about-author-inner{
  padding: 20px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  text-align: center;
}
.widget-about-author-inner img{
  border-radius: 50%;
  width: 100px;
  margin: 0 auto 20px;
}
.widget-about-author-inner h5{
  margin-bottom: 10px;
}
.widget-about-author .andro_sm{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 8px 8px;
}
.widget-about-author .andro_sm li + li{
  margin: 0;
}
.widget-about-author .andro_sm li{
  flex: 1;
}
.widget-about-author .andro_sm li:first-child a{
  border-radius: 0 0 0 8px;
}
.widget-about-author .andro_sm li:last-child a{
  border-radius: 0 0 8px 0;
}
.widget-about-author .andro_sm li a{
  background-color: #F0F2F3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #838383;
}
.widget-about-author .andro_sm li a:hover{
  background-color: #5D5D5D;
  color: #fff;
}

.widget-recent-posts .post > a{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 10px;
}
.widget-recent-posts .post > a img{
  transition: .3s;
  border-radius: 8px;
}
.widget-recent-posts .post > a img:hover{
  transform: scale(1.1) rotate(2deg);
}
.widget-recent-posts .post h6{
  margin-bottom: 10px;
}
.widget-recent-posts .post h6 a{
  color: #18181D;
}
.widget-recent-posts .post h6 a:hover,
.widget-recent-posts .post span a:hover{
  color: #50BD4D;
}
.widget-recent-posts .post span{
  font-size: 13px;
  line-height: 1.7;
  font-weight: 600;
  color: #18181D;
}
.widget-recent-posts .post span a{
  font-weight: 400;
  text-decoration: underline;
  color: #18181D;
}
.widget-recent-posts .post + .post{
  margin-top: 30px;
}

.tags a,
.tagcloud a,
.entry-content .wp-block-tag-cloud a{
  display: inline-block;
  font-size: 13px;
  padding: 3px 12px;
  color: #848486;
  border: 1px solid #F0F2F3;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  background-color: #fff;
  margin: 6px 6px 0 0;
  border-radius: 8px;
}
.tags a:hover,
.tagcloud a:hover,
.entry-content .wp-block-tag-cloud a:hover{
  background-color: #f5f5f5;
  border-color: rgba(0, 0, 0, 0.1);
  color: #848486;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.andro_newsletter-form{
  position: relative;
  background-color: #F0F2F3;
  padding: 20px;
  border-radius: 8px;
}
.andro_newsletter-form.primary-bg{
  background-color: #50BD4D;
}
.andro_newsletter-form h5{
  margin-bottom: 15px;
}

.deals-slider .slick-dots{
  justify-content: center;
  margin: 10px 0 0;
}
.deals-slider .andro_product:hover .andro_countdown-timer{
  background-color: #F0F2F3;
}

.andro_countdown-timer{
  text-align: center;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  padding: 10px;
  font-weight: 600;
  margin: 20px 0 0;
  transition: .3s;
}

/* 6.3. Products */
.andro_product{
  position: relative;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border-radius: 8px;
  margin-bottom: 30px;
  /* min-height: 211px; */
    /* max-height: 211px; */
}
.andro_product .andro_product-thumb{
  margin: 0 0 20px;
  overflow: hidden;
}
.andro_product .andro_product-thumb.thumb-sm{
  max-width: 200px;
  margin: 0 auto 20px;
}
.andro_product .andro_product-thumb img{
  transition: .3s;
}
.andro_product .andro_product-thumb:hover img{
  transform: scale(1.1) rotate(3deg);
}
.andro_product .andro_product-body h5{
  margin: 0 0 15px;
  word-break: break-word;
}
.andro_product .andro_product-body .andro_product-title a{
  color: #18181D;
}
.andro_product .andro_product-body .andro_product-title a:hover{
  color: #40A13D;
}
.andro_product .andro_badge-featured{
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffa80a;
  color: #fff;
  border-radius: 8px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  font-size: 13px;
  padding: 3px 12px;
}
.andro_product .andro_badge-featured i{
  margin-right: 5px;
  display: inline-block;
}
.andro_product .andro_badge-sale{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  background-color: #f36523;
  font-weight: 600;
  color: #fff;
  font-size: 13px;
  line-height: 1.7;
  padding: 3px 12px;
  border-radius: 0 0 8px 8px;
}
.andro_product .andro_rating-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.andro_product .andro_rating-wrapper span{
  font-weight: 600;
}
.andro_product .andro_product-footer{
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #F0F2F3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.andro_product-price span{
  display: block;
}
.andro_product-price span:first-child{
  font-size: 18px;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  color: #18181D;
  font-weight: 700;
}
.andro_product-price .main-price{
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #888;
  font-weight: 700;
  text-decoration: line-through;
}
.andro_product.andro_product-has-controls .andro_product-body .andro_product-price{
  margin-bottom: 10px;
}
.andro_product.andro_product-has-controls .andro_product-body .andro_product-price span{
  display: inline-block;
}
.andro_product.andro_product-has-controls .andro_product-body .andro_product-price span:first-child{
  font-size: 16px;
}
.andro_product.andro_product-has-controls .andro_product-body .andro_product-price span + span{
  margin-left: 5px;
}
.andro_product.andro_product-has-buttons .andro_product-footer{
  border-top: 0;
  padding: 0;
  margin: 20px -20px -20px -20px;
}
.andro_product.andro_product-has-buttons .andro_product-footer .andro_product-buttons{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 8px;
}
.andro_product.andro_product-has-buttons .andro_product-footer .andro_product-buttons a{
  flex: 1;
  box-shadow: none;
}
.andro_product.andro_product-has-buttons .andro_product-footer .andro_product-buttons a{
  border-radius: 0;
}
.andro_product-stock{
  font-weight: 600;
}
.andro_product-stock.instock{
  color: #58da58;
}
.andro_product-stock.outofstock{
  color: #f54978;
}

.andro_product .andro_rating{
  margin-bottom: 0;
}
.andro_product-controls{
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.andro_product-controls a{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #F0F2F3;
}
.andro_product-controls a i{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
}
.andro_product-controls a:hover{
  background-color: #50BD4D;
  color: #fff;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}

/* 6.3.1 Products (List View) */
.andro_product.andro_product-list{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.andro_product.andro_product-list .andro_product-thumb{
  width: 125px;
  margin-right: 15px;
}
.andro_product.andro_product-list  .andro_product-body{
  flex: 1;
}
.andro_product.andro_product-list  .andro_product-footer{
  padding-top: 0;
  border-top: 0;
}
.andro_product.andro_product-list .andro_rating-wrapper{
  margin-bottom: 10px;
}
.andro_product.andro_product-list .andro_product-controls{
  justify-content: space-between;
}
.andro_product.andro_product-list.andro_product-has-buttons .andro_product-footer{
  width: calc(100% + 40px);
}
.andro_product.andro_product-list.andro_product-has-buttons .andro_product-footer{
  margin: 10px -20px -20px -20px;
}

/* 6.3.2 Products (Minimal View) */
.andro_product.andro_product-minimal{
  text-align: center;
}
.andro_product.andro_product-minimal .andro_product-title{
  margin-bottom: 0px;
  font-size: 16px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.andro_product.andro_product-minimal .andro_product-footer{
  border: 0;
  padding-top: 0;
  margin-top: 5px;
  display: block;
  text-align: center;
}
.andro_product.andro_product-minimal .andro_product-price{
  margin: 15px 0;
}
.andro_product.andro_product-minimal .andro_product-price span{
  font-size: 14px;
  display: inline-block;
  line-height: 1.7;
}
.andro_product.andro_product-minimal .andro_product-price span + span{
  margin-left: 5px;
  font-size: 13px;
}
.andro_product.andro_product-minimal .andro_rating-wrapper{
  justify-content: center;
}
.andro_product.andro_product-minimal.andro_product-has-buttons .andro_product-footer .andro_product-buttons{
  margin-top: 20px;
}

.andro_shop-global{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #F0F2F3;
  background-color: #fff;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.andro_shop-global p{
  margin-bottom: 0;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child{
  background-color: #5D5D5D;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before{
  border-top-color: #5D5D5D;
}
.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child{
  background-color: #383838;
}

/* 6.3.3 Products Quick View & Details */
.andro_quick-view-modal .andro_product-single-content{
  padding: 0 40px;
}
.andro_quick-view-modal .close-btn{
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}
.andro_product-single-content .andro_rating-wrapper{
  margin-bottom: 0;
}
.andro_product-single-content .andro_product-excerpt,
.andro_product-single-content .andro_product-variation-wrapper{
  margin-bottom: 25px;
}
.andro_product-single-content .andro_product-price{
  margin-bottom: 20px;
}
.andro_product-single-content .andro_product-price span:first-child{
  font-size: 18px;
}
.andro_product-single-content .andro_product-price span{
  display: inline-block;
}
.andro_product-single-content .andro_product-price span + span{
  margin-left: 10px;
}

.andro_product-atc-form .qty-outter{
  display: flex;
  align-items: center;
}
.andro_product-atc-form .qty-outter .andro_btn-custom + .qty{
  margin-left: 20px;
}
.qty{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.qty input {
  background-color: #fff;
  border: 0;
  outline: none;
  width: 50px;
  height: 50px;
  text-align: center;
  font-weight: 600;
  border: 1px solid #F0F2F3;
}
.qty span.qty-subtract {
  border-right: 0;
  border-radius: 25px 0 0 25px;
}
.qty span.qty-add {
  border-left: 0;
  border-radius: 0 25px 25px 0;
}
.qty span:hover {
  background-color: #F0F2F3;
}
.qty span {
  cursor: pointer;
  width: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #F0F2F3;
  font-size: 13px;
  background-color: #fff;
  transition: .3s;
}

.andro_product-additional-info .nav{
  flex-direction: column;
}
.andro_product-additional-info .nav-link{
  margin: 0;
  border-radius: 8px;
}
.andro_product-additional-info .nav-item + .nav-item .nav-link{
  margin-top: 10px;
}
.andro_product-additional-info .tab-content{
  padding: 40px;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  margin-top: 0;
}
.andro_product-additional-info .tab-content table{
  margin: 0;
}
.andro_product-additional-info .andro_rating-wrapper,
.andro_product-additional-info .comment-body .andro_rating{
  margin-bottom: 20px;
}
.andro_product-additional-info .comment-form {
  margin-bottom: 50px;
}

.andro_product-single-controls{
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.andro_product-single-controls .andro_add-to-favorite{
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F0F2F3;
  margin: 0 10px 0 0;
}
.andro_product-single-controls .andro_add-to-favorite:hover{
  background-color: #F0F2F3;
}
.andro_product-single-controls .andro_add-to-favorite i{
  font-size: 21px;
  color: #838383;
}

.andro_product-meta{
  margin: 20px 0 0;
}
.andro_product-single-2 .andro_product-meta{
  margin: 20px 0 30px;
}
.andro_product-meta li{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.andro_product-meta li > span{
  font-weight: 600;
  color: #18181D;
  width: 120px;
}
.andro_product-meta li .andro_product-meta-item span,
.andro_product-meta li a{
  color: #838383;
}
.andro_product-meta li a:hover{
  color: #50BD4D;
}

.andro_product-single-thumb,
.andro_product-single-zoom > img{
  background-color: #fff;
}

/* 6.3.4. Cart & Wishlist */
td.remove{
  width: 30px;
}
.andro_cart-product-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.andro_cart-product-wrapper img {
  width: 50px;
  margin-right: 15px;
}
.andro_cart-product-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.andro_cart-product-wrapper h6{
  margin-bottom: 0;
  font-size: 14px;
}
.andro_cart-product-wrapper h6 a{
  color: #18181D;
}
.andro_cart-product-wrapper h6 a:hover{
  color: #50BD4D;
}
.andro_cart-product-wrapper p {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 21px;
  color: #848486;
}

/* 6.3.5. Checkout */
.andro_notice {
  background-color: #F0F2F3;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
}
.andro_notice p {
  margin: 0;
  font-weight: 600;
}
.andro_notice p a:hover {
  text-decoration: underline;
}
.andro_notice-content {
  margin-bottom: 30px;
  display: none;
}

/* 6.3.6 Products (Recent View) */
.andro_product-recent{
  position: relative;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border-radius: 8px;
  transition: .3s;
}
.andro_product-recent:hover{
  background-color: #F0F2F3;
}
.andro_product-recent .andro_product-recent-thumb{
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}
.andro_product-recent + .andro_product-recent{
  margin-top: 10px;
}
.andro_product-recent img{
  width: 75px;
  margin-right: 10px;
}

.andro_product-recent-content .andro_product-price span{
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}
.andro_product-recent-content .andro_product-price span + span{
  margin-left: 5px;
}
.andro_product-recent-content h6{
  margin-bottom: 5px;
  font-weight: 600;
}
.andro_product-recent button{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  margin-left: auto;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  width: 40px;
  height: 40px;
}

/* 6.4. Categories Megamenu */
.andro_category-mm{
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.andro_category-mm-header{
  background-color: #f36523;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
.andro_category-mm-header h6{
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
.andro_category-mm-header i{
  color: #fff;
  width: 20px;
  font-size: 12px;
}
.andro_category-mm-body{
  position: relative;
  background-color: #fff;
  border: 1px solid #F0F2F3;
  border-radius: 0 0 8px 8px;
}
.andro_category-mm-body ul{
  margin: 0;
}
.andro_category-mm-body .andro_category-mm-item-has-children > a::after{
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-left: auto;
  font-size: 11px;
  transition: transform .3s;
}
.andro_category-mm-body .andro_category-mm-item-has-children > a:hover::after{
  transform: translateX(3px);
}
.andro_category-mm-body .andro_category-mm-item-has-children > a{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.andro_category-mm-body .andro_category-mm-item.andro_category-mm-item-expand{
  position: static;
}
.andro_category-mm-body .andro_category-mm-item.andro_category-mm-item-expand > ul{
  height: 100%;
  border-radius: 0 8px 8px 0;
}
.andro_category-mm-body ul li{
  display: block;
  position: relative;
  margin: 0;
  padding: 20px 20px 10px 20px;
}
.andro_category-mm-body > ul > li:last-child{
  padding: 10px 20px 20px 20px;
}
.andro_category-mm-body ul li + li{
  padding: 10px 20px;
}
.andro_category-mm-body > ul > li > ul{
  position: absolute;
  top: 0;
  left: 95%;
  transition: .3s;
  background-color: red;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  background-color: #fff;
  border: 1px solid #F0F2F3;
  opacity: 0;
  visibility: hidden;
  z-index: 90;
  width: 100%;
  border-radius: 0 8px 8px 8px;
}
.andro_category-mm-body > ul > li > ul.andro_category-mm-2-cols{
  min-width: 400px;
}
.andro_category-mm-body > ul > li > ul.andro_category-mm-1-cols{
  min-width: 200px;
}
.andro_category-mm-body > ul > li:hover > ul{
  opacity: 1;
  visibility: visible;
  left: 100%;
}
.andro_category-mm-body ul li a{
  color: #18181D;
  display: block;
}
.andro_category-mm-body ul li a i{
  width: 20px;
  font-size: 12px;
}
.andro_category-mm-body ul li a:hover{
  color: #50BD4D;
}
.andro_category-mm-body > ul > li > ul > li{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
}
.andro_category-mm-body > ul > li > ul > li ul li:last-child{
  padding: 10px 20px 20px 20px;
}
.andro_category-mm-body > ul > li > ul > li img{
  display: block;
  width: 100%;
  flex: 0 100%;
  border-radius: 0 8px 0 0;
}
.andro_category-mm-banner{
  position: relative;
  z-index: 1;
  width: 100%;
}
.andro_category-mm-banner::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(0,0,0,.6), transparent);
}
.andro_category-mm-banner-desc{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
}
.andro_category-mm-banner-desc h6,
.andro_category-mm-banner-desc p{
  margin-bottom: 0;
  color: #fff;
}

/* 6.5. Banners */
.andro_banner .slick-track,
.andro_banner .slick-slide{
  margin: 0;
}
.andro_banner{
  padding: 5px;
  background-color: #F0F2F3;
  border-radius: 8px;
}
.andro_banner h1{
  font-size: 32px;
}
.andro_banner p:first-child{
  margin-bottom: 10px;
}
.andro_banner p{
  margin-bottom: 20px;
}
.andro_banner-slider .slick-track{
  padding: 10px 0;
}
.andro_flex-menu > ul{
  display: flex;
  margin: 0;
  align-items: center;
}
.andro_flex-menu ul li{
  margin: 0;
}
.andro_flex-menu > ul > li > a{
  padding: 0 10px 20px 10px;
  display: block;
  font-weight: 600;
  color: #18181D;
}
.andro_flex-menu > ul > li > a:hover{
  color: #50BD4D;
}
.andro_flex-menu > ul > li:first-child > a{
  padding-left: 0;
}
.andro_banner .slick-dots{
  padding: 0 0 10px;
}
.andro_banner .slick-dots li button{
  background-color: #fff;
}
.andro_banner .slick-dots li.slick-active button{
  background-color: #50BD4D;
}


/* Style 2 */
.banner-2.andro_banner,
.banner-2.andro_banner .slick-track{
  padding: 0;
}
.banner-2.andro_banner .andro_banner-slider-inner{
  padding: 120px 15px 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-2.andro_banner .andro_banner-slider-text{
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  overflow: hidden;
  position: relative;
}
.banner-2.andro_banner .andro_banner-slider-text img.img-1{
  position: absolute;
  bottom: -40px;
  right: -70px;
  width: 210px;
}
.banner-2.andro_banner .andro_banner-slider-text p:first-child{
  margin-bottom: 10px;
}
.banner-2.andro_banner .andro_banner-slider-text p{
  max-width: 500px;
  margin: 0 auto 20px;
}
.banner-2.andro_banner .andro_banner-slider-text h1{
  text-transform: uppercase;
  font-size: 64px;
  max-width: 700px;
  margin: 0 auto 20px;
  color: #fff;  /* Fallback: assume this color ON TOP of image */
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banner-2.andro_banner .slick-track,
.banner-3.andro_banner .slick-track{
  display: flex;
}
.banner-2.andro_banner .slick-slide,
.banner-3.andro_banner .slick-slide{
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}


/* Style 3 */
.banner-3.andro_banner{
  background-color: #fff;
}

/* 6.6. Icon Blocks */
.andro_icon-block{
  padding: 20px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #F0F2F3;
  background-color: #fff;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  transition: .3s;
}
.andro_icon-block i{
  font-size: 40px;
  line-height: 1;
  margin: 0 0 15px;
  color: #838383;
  display: inline-block;
}
.andro_icon-block.has-link:hover{
  background-color: #F0F2F3;
}
.andro_icon-block a{
  display: block;
}
.andro_icon-block a h5{
  font-size: 14px;
}
.andro_icon-block h5{
  font-size: 18px;
  margin-bottom: 0;
}
.andro_icon-block h5 + p{
  margin-top: 10px;
}
.andro_icon-block p{
  margin-bottom: 0;
}
.andro_icon-block svg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.andro_icon-block .andro_svg-stroke-shape-anim{
  stroke-dasharray: 100 1000;
  stroke-dashoffset: -620;
  stroke: #50BD4D;
  stroke-width: 5px;
  fill: transparent;
  -webkit-transition: stroke-width 1s,stroke-dashoffset 1s,stroke-dasharray 1s;
  transition: stroke-width 1s,stroke-dashoffset 1s,stroke-dasharray 1s;
  width: 100%;
  height: 100%;
}
.andro_icon-block:hover .andro_svg-stroke-shape-anim{
  stroke-width: 3px;
  stroke-dashoffset: 0;
  stroke-dasharray: 1110;
}

/* Style 2 */
.andro_icon-block.icon-block-2{
  padding: 0;
  background-color: #fff;
  border: 0;
  box-shadow: none;
}
.andro_icon-block.icon-block-2 i{
  color: #50BD4D;
}

/* 6.7. Call to Actions */
.andro_cta-notice{
  padding: 40px;
  border-radius: 8px;
}
.andro_cta-notice-inner{
  max-width: 600px;
}
.andro_cta-notice-inner p{
  margin-bottom: 25px;
}


.andro_cta{
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  overflow: hidden;
}
.andro_cta::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  border-radius: 8px;
  transition: .3s;
}
.andro_cta:hover::before{
  background-color: rgba(0,0,0,.3);
}
.andro_cta img{
  border-radius: 8px;
  width: 100%;
}

.andro_cta-content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 20px;
}
.andro_cta-content h4{
  margin-bottom: 10px;
}
.andro_cta-content p{
  margin-bottom: 30px;
  max-width: 350px;
}

/* 6.8. Login & Signup */
.andro_auth-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 3px 12px -4px rgba(0,0,0,.1);
          box-shadow: 0 3px 12px -4px rgba(0,0,0,.1);
}
.andro_auth-description{
  width: 600px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.andro_auth-description-inner{
  text-align: center;
  max-width: 400px;
}
.andro_auth-description-inner h2{
  margin: 0;
  color: #fff;
}
.andro_auth-description-inner p{
  margin: 30px 0;
  color: #fff;
}
.andro_auth-description-inner i{
  color: #fff;
  line-height: 1;
  font-size: 40px;
  margin-bottom: 30px;
  display: block;
}
.andro_social-login{
  margin-bottom: 20px;
}
.andro_social-login + p a{
  display: inline-block;
}
.andro_auth-form{
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #F0F2F3;
  padding: 60px;
  text-align: center;
}
.andro_auth-form h2{
  margin-bottom: 50px;
}
.andro_auth-form form{
  max-width: 400px;
  margin: 0 auto;
}
.andro_auth-form .andro_btn-custom{
  margin-top: 20px;
}
.andro_auth-form a{
  display: block;
  color: #18181D;
  text-decoration: underline;
}
.andro_auth-seperator{
  margin: 30px 0;
  text-align: center;
}
.andro_auth-seperator span{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #9F9E9E;
  font-size: 13px;
  font-weight: 500;
}
.andro_auth-seperator span::before{
  content: '';
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: rgba(0,0,0,.1);
  height: 1px;
  margin-right: 20px;
}
.andro_auth-seperator span::after{
  content: '';
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: rgba(0,0,0,.1);
  height: 1px;
  margin-left: 20px;
}
.andro_social-login-btn{
  cursor: pointer;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 24px;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 3px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-radius: 25px;
}
.andro_social-login-btn i{
  margin-right: 5px;
}
.andro_auth-mini{
  background-color: #F0F2F3;
  padding: 20px;
  border-radius: 8px;
}
.andro_auth-mini h5{
  margin-bottom: 15px;
}
.andro_auth-mini a{
  display: block;
  color: #18181D;
  text-decoration: underline;
}
.andro_auth-mini a:hover{
  color: #50BD4D;
}

/* 6.9. Error 404 */
.andro_404-container{
  text-align: center;
}
.andro_404-container img{
  margin-bottom: 30px;
}
.andro_404-container p{
  max-width: 500px;
  margin: 0 auto 20px;;
}

/* 6.10. Testimonials */
.andro_testimonials .slick-dots{
  justify-content: center;
  margin: 10px 0 0;
}
.andro_testimonials-inner{
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  text-align: center;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border: 1px solid #F0F2F3;
  border-radius: 8px;
}
.andro_testimonials-inner::before{
  content: '\f113';
  font-family: flaticon;
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 48px;
  line-height: 1;
  opacity: .3;
}
.andro_testimonials-inner img{
  border-radius: 50%;
  width: 100px;
  margin: 0 auto 20px;
}
.andro_testimonials-inner span{
  display: block;
  margin: 0 0 10px;
  font-weight: 600;
}
.andro_testimonials-inner p{
  margin: 0;
}
.andro_testimonials-inner h5{
  margin-bottom: 10px;
}
.andro_testimonials .andro_sm{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 8px 8px;
}
.andro_testimonials .andro_sm li + li{
  margin: 0;
}
.andro_testimonials .andro_sm li{
  flex: 1;
}
.andro_testimonials .andro_sm li:first-child a{
  border-radius: 0 0 0 8px;
}
.andro_testimonials .andro_sm li:last-child a{
  border-radius: 0 0 8px 0;
}
.andro_testimonials .andro_sm li a{
  background-color: #F0F2F3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #838383;
}
.andro_testimonials .andro_sm li a:hover{
  background-color: #5D5D5D;
  color: #fff;
}

/* Style 2 */
.andro_testimonial{
  margin-bottom: 30px;
}
.andro_testimonial-body{
  position: relative;
  border-radius: 8px;
  background-color: #F0F2F3;
  padding: 20px;
}
.andro_testimonial-body::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #F0F2F3;
}
.andro_testimonial-body h5{
  margin-bottom: 5px;
}
.andro_testimonial-body p {
  margin-bottom: 0;
}
.andro_testimonial-body::after {
  content: "\f10e";
  position: absolute;
  font-family: fontAwesome;
  font-size: 34px;
  opacity: .3;
  display: block;
  top: 20px;
  right: 20px;
}
.andro_testimonial-author {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.andro_testimonial-author img {
  width: 80px;
  border-radius: 50%;
  margin-right: 15px;
}
.andro_testimonial-author-inner {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.andro_testimonial-author-inner h6 {
  margin-bottom: 0;
}
.andro_testimonial-author-inner span {
  line-height: 20px;
  font-size: 13px;
  font-weight: 600;
  color: #848486;
}

/* 6.11. Companies */
.andro_companies{
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.andro_companies .andro_companies-item{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F0F2F3;
  cursor: pointer;
  position: relative;
}
.andro_companies .andro_companies-item img{
  transition: .3s;
}
.andro_companies .andro_companies-item-name{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.andro_companies .andro_companies-item-name span{
  font-weight: 600;
  display: block;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  transition: .3s;
}
.andro_companies .andro_companies-item:hover img{
  transform: translateY(5px);
  opacity: 0;
  visibility: hidden;
}
.andro_companies .andro_companies-item:hover span{
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/* 6.12. Newsletter Popup */

#androNewsletterPopup .modal-dialog{
  max-width: 600px;
  margin: .5rem auto;
}
.andro_newsletter-popup-modal .modal-header{
  padding: 0;
  height: 300px;
  overflow: hidden;
}
.andro_newsletter-popup-modal .modal-header .close,
.andro_newsletter-popup-modal .modal-header .close:hover,
.andro_newsletter-popup-modal .modal-header .close:not(:disabled):not(.disabled):hover{
  background-color: transparent;
}

.andro_newsletter-popup-modal .modal-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  background-size: cover;
}

.andro_newsletter-popup-modal .modal-body h3{
  font-size: 30px;
  margin: 0 0 10px;
}
.andro_newsletter-popup-modal .modal-body p{
}
.andro_newsletter-popup-text-wrapper{
  text-align: center;
}
.andro_newsletter-popup-modal .close-btn{
  position: absolute;
  top: 10px;
  right: 10px;
}
.andro_newsletter-popup-modal .form-control{
  width: 300px;
  margin-bottom: 10px;
}
.andro_newsletter-popup-modal .mc4wp-form-fields .btn-custom{
  width: 100%;
}
.andro_newsletter-popup-modal .andro_btn-custom + span{
  display: block;
  cursor: pointer;
  margin-top: 20px;
}
.andro_newsletter-popup-modal .andro_btn-custom + span:hover{
  text-decoration: underline;
}

/*======================
7. Misc
========================*/
.slick-list{
  margin: 0 -15px;
}
.slick-slide{
  outline: none;
  margin: 0 15px;
}

/* Arrows */
.andro_arrows{
  display: flex;
  align-items: center;
  margin-left: auto;
}
.andro_arrows .slick-arrow{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f36523;
  transition: .3s;
  cursor: pointer;
  color: #fff;
  font-size: 10px;
}
.andro_arrows .slick-arrow:hover{
  color: #fff;
  background-color: #50BD4D;
  box-shadow: 4px 3px 24px rgba(80, 189, 77, 0.5);
}
.andro_arrows .slick-arrow.slider-prev{
  margin-right: 10px;
}

.slick-prev,
.slick-next{
  /* background-color: #F0F2F3;
  border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 40px;
  height: 40px; */
  color: #18181D;
  transition: .3s;
  z-index: 9;
  /* opacity: 1;
  visibility: visible; */
}
.banner-section .slick-prev, .banner-section .slick-next{
  visibility: visible;
  opacity: 1;
}
.category-section .slick-next, .category-section .slick-prev{
  visibility: visible;
  opacity: 1;
}
.section:hover .slick-next{
  opacity: 1;
  visibility: visible;
}
.section:hover .slick-prev{
  opacity: 1;
  visibility: visible;
}

.slick-prev:before,
.slick-next:before{
  color: #18181D;
  font-family: "Font Awesome 5 Free";
  content: ">";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1.7;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  font-size: 24px;
}
.slick-prev:before{
  content: "<";
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus{
  color: #000;
}
.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before{
  color: #000;
}
.slick-prev{
  left: -22px;
}
.slick-next {
  right: -22px;
}
.banner-section .slick-prev{
  left: -3px;
}
.banner-section .slick-next{
  right: -3px;
}
/* Dots */
.slick-dots{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0;
}
.slick-dots li{
  margin: 0 5px;
  text-align: center;
}
.slick-dots li button{
  font-size: 0;
  width: 12px;
  height: 12px;
  background-color: #F0F2F3;
  border: 1px solid #ccc;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  outline: none;
}
.slick-dots li.slick-active button{
  -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
          transform: scale(1.3);
  background-color: #5d5d5d;
}
.slick-slide{
  outline: none;
}

/* pagination */
.pagination{
  margin-top: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0;
}
.pagination .page-item{
  margin: 0;
}

.pagination .page-item:last-child .page-link,
.pagination .page-item:first-child .page-link,
.pagination .page-item .page-link{
  border-radius: 8px;
}

.pagination .page-link{
  color: #18181D;
  border: 0;
  background-color: #F0F2F3;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px;
  margin-left: 15px;
}
.pagination .page-item.active .page-link{
  background-color: #50BD49;
}
.pagination .page-item .page-link:hover,
.pagination .page-item .page-link:focus{
  color: #50BD49;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tooltip{
  font-size: 13px;
}

.load-more{
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  padding: 10px;
  border: 1px solid #F0F2F3;
  border-radius: 8px;
  font-weight: 600;
  background-color: #fff;
}
.load-more:hover{
  background-color: #F0F2F3;
}

/* Accordion */
.accordion .card {
  margin-bottom: 0;
}
.accordion .card .card-header {
  position: relative;
  background-color: #fff;
  border-bottom: 0;
  padding: .75rem 1.25rem;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: none;
  font-weight: 700;
  color: #18181D;
}

.accordion .card-header[aria-expanded="true"] {
  background-color: #f8f9fa;
  color: #18181D;
}

.accordion .card-header::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1.25rem;
  width: 10px;
  height: 2px;
  background-color: #848486;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 1;
}

.accordion .card-header::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1.25rem;
  width: 10px;
  height: 2px;
  background-color: #848486;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 1;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordion .card-header[aria-expanded="true"]::before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  opacity: 0;
}

.accordion .card-header[aria-expanded="true"]::after, .accordion .card-header[aria-expanded="true"]::before {
  background-color: #18181D;
}

.accordion .card-header i {
  position: relative;
  top: 0;
  left: 0;
  margin-right: 10px;
  font-size: 20px;
}

.accordion .card-header[aria-expanded="true"] i {
  color: #50BD49;
}

.accordion .card {
  border: #F0F2F3;
}

.accordion.with-gap .card-header {
  border-radius: 3px 3px;
}

.accordion.with-gap .card {
  margin-bottom: 20px;
}
.accordion.with-gap .card:last-child{
  margin-bottom: 0;
}
.accordion.with-gap .card:first-of-type, .accordion.with-gap .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid #F0F2F3;
}

/* Rating */
.andro_rating-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.andro_rating-wrapper > span{
  margin-left: 10px;
  color: #848486;
  font-size: 13px;
}
.andro_rating{
  margin-bottom: 5px;
}
.andro_rating i{
  margin-right: 5px;
  font-size: 12px;
  color: #848486;
}
.andro_rating i.active{
  color: #ffa80a;
}

/*======================
8. Repsonsive
========================*/

@media(max-width: 1199px){

  .andro_auth-description {
    width: 450px;
  }
  .checkout-billing{
    margin-top: 50px;
  }

}

@media (max-width: 991px) {

  .andro_grid-slider .slick-prev,
  .andro_grid-slider .slick-next{
    display: none;
  }

  .banner-3.andro_banner .andro_arrows{
    margin-top: 10px;
  }
  .banner-3.andro_banner .andro_arrows .slick-arrow{
    background-color: #fff;
  }
  .banner-3.andro_banner .andro_arrows .slick-arrow:hover{
    background-color: #50BD4D;
  }
  .banner-2.andro_banner .andro_banner-slider-inner{
    padding: 80px 15px;
  }
  .banner-2.andro_banner .andro_banner-slider-text{
    padding: 20px;
  }
  .banner-3.andro_banner{
    background-image: none;
    /* padding: 0;
    background-color: #F0F2F3; */
    padding: 0 20px;
    background-color: transparent;
    border-radius: 0;
  }
  .slide img{
    min-height: 0 !important;
  }
  .mb-lg-20{
    margin-bottom: 20px;
  }
  .mb-lg-30,
  .andro_upsells{
    margin-bottom: 30px;
  }
  .mr-lg-30{
    margin-left: 0;
  }
  .section.section-padding.category_section{
    margin-top: 0;
    padding-top: 40px;
  }
  .section {
    padding: 40px 0;
  }
  .section.section-padding{
    padding: 40px 0 10px;
  }
  .andro_section-fw{
    padding: 0 15px;
  }
  .checkout-billing{
    margin-top: 40px;
  }

  .banner-section .slick-prev{
    left: -22px;
  }
  .banner-section .slick-next{
    right: -22px;
  }
  .navbar-brand{
    width: 150px;
  }
  .andro_header-controls .aside-toggler{
    height: 58px;
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F0F2F3;
    border-radius: 2px;
    margin-left: 10px;
  }
  .andro_header-controls .aside-toggler:hover{
    background-color: #F0F2F3;
  }
  .andro_header-bottom,
  .andro_header-bottom .navbar-nav,
  .andro_header-middle .navbar-nav,
  .andro_header .andro_search-adv{
    display: none;
  }
  .andro_header.header-2 .andro_header-middle .navbar{
    padding: 15px 0;
  }
  .aside-toggler{
    display: block;
  }
  .andro_aside-overlay.aside-trigger-left,
  .andro_aside.andro_aside-left{
    display: block;
  }
  .andro_post-single .andro_post-share{
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .andro_category-mm{
    margin-bottom: 30px;
  }
  .andro_category-mm-body .andro_category-mm-item.andro_category-mm-item-expand > ul{
    height: auto;
  }
  .andro_category-mm-body > ul > li > ul{
    top: 100%;
    left: 0;
  }
  .andro_category-mm-body > ul > li:hover > ul{
    left: 0;
  }
  .andro_category-mm-body > ul > li > ul > li img{
    border-radius: 0;
  }
  .sidebar{
    margin-top: 10px;
  }
  .sidebar .sidebar-widget:last-child{
    margin-bottom: 40px;
  }
  .load-more{
    margin: 40px 0 30px;
  }
  .pagination{
    margin: 10px 0 30px;
  }
  .andro_icon-block svg{
    display: none;
  }
  .andro_footer{
    padding: 40px 0 0 0;
  }
  .andro_footer-top{
    margin-bottom: 40px;
  }
  .footer-widget{
    padding-bottom: 40px;
  }
  .andro_instagram{
    max-width: 400px;
    padding: 40px;
  }
  .andro_post.andro_post-list{
    padding: 20px;
    align-items: flex-start;
  }
  .andro_post.andro_post-list .andro_post-thumb a,
  .andro_post.andro_post-list .andro_post-thumb a img{
    border-radius: 8px;
  }
  .andro_product-additional-info .tab-content{
    margin-top: 30px;
  }
  .andro_auth-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .andro_auth-description {
    width: 100%;
  }
  .andro_companies .andro_companies-item{
    min-height: 110px;
  }

}

@media (max-width: 768px){

  h1{
    font-size: 34px;
  }
  h2{
    font-size: 30px;
  }
  h3{
    font-size: 26px;
  }
  h4{
    font-size: 22px;
  }
  .andro_banner{
    padding: 15px;
  }
  .banner-2.andro_banner .andro_banner-slider-text h1{
    font-size: 44px;
  }
  .andro_responsive-table thead {
    display: none;
  }
  .andro_responsive-table td {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
    padding: 10px;
    border-bottom: 0;
  }
  .andro_responsive-table tr.total td::before{
    display: none;
  }
  .andro_responsive-table tr.total td:first-child{
    border-top: 0;
    border-right: 0;
  }
  .andro_responsive-table tr.total td:last-child{
    border-left: 0;
    border-top: 0;
  }
  .andro_responsive-table tr.total td:nth-child(2){
    display: none;
  }
  .andro_responsive-table tr.total td{
    width: auto;
    text-align: left;
  }
  .andro_responsive-table tr.total{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .andro_responsive-table td::before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
  }
  .andro_responsive-table td.remove::before {
    display: none;
  }
  .andro_responsive-table tr {
    border-bottom: 1px solid #F0F2F3;
  }

  .andro_responsive-table .cart-product-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    padding: 0;
  }
  .andro_responsive-table .cart-product-wrapper img {
    margin-right: 0;
    margin-left: 10px;
  }
  .andro_quick-view-modal .andro_product-single-content{
    padding: 0;
  }
  .andro_quick-view-modal img{
    margin-bottom: 30px;
  }

  .footer-widget{
    text-align: center;
  }
  .andro_footer .social-media{
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .andro_footer-buttons a{
    margin-bottom: 5px;
  }
  .footer-widget ul li a{
    -webkit-transform: translate(0);
        -ms-transform: translate(0);
            transform: translate(0);
  }
  .footer-widget ul li a::before{
    display: none;
  }
  .andro_footer-top{
    display: block;
    text-align: center;
  }
  .andro_footer-logo{
    margin: 0 auto 20px;
  }
  .andro_footer-bottom ul{
    display: block;
    text-align: center;
  }
  .andro_footer-bottom ul li + li{
    margin-top: 10px;
  }
  .andro_footer-bottom .andro_footer-copyright{
    display: block;
    text-align: center;
  }
  .andro_footer-bottom .andro_footer-copyright > a{
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 20px;
  }
  .andro_post.andro_post-list{
    display: block;
    padding: 0;
  }
  .andro_post.andro_post-list .andro_post-body{
    padding: 20px;
  }
  .andro_post.andro_post-list .andro_post-thumb a,
  .andro_post.andro_post-list .andro_post-thumb a img{
    border-radius: 8px 8px 0 0;
  }
  .andro_subheader-inner{
    display: block;
  }
  .andro_subheader .breadcrumb{
    margin-top: 20px;
  }
  .andro_newsletter-popup-modal .modal-header{
    height: auto;
  }

  .category-box{

    top: 54px;
    font-weight: 700;
    line-height: 20px;
    width: auto !important;
    text-align: center;
    margin: 0 auto;
}

}

@media (max-width: 575px){
  p{
    font-size: 11px;
  }
  .category-section{
    padding: 0;
  }

  .category-wrapper{
    height: 150px !important;
  }

  .category-wrapper.sub-category{
    height: 180px !important;
  }

  .category-box {
    top: 48px !important;
    width: auto !important;
    text-align: center;
    margin: 0 auto;
}
.category-img {
  width: 35%  !important;
  margin: 0 auto;
}
.slide img {
  min-height: 150px !important;
  max-height: 150px !important;
  overflow: hidden !important;
}
.category-box p{
  font-size: 10px !important;
  font-weight: normal !important;
}

  .andro_newsletter-popup-modal .modal-body{
    padding: 20px;
  }
  .andro_newsletter-popup-modal .form-control{
    width: 100%;
  }
  .andro_header.header-2 .andro_header-middle .navbar{
    padding: 10px 0;
  }

  .banner-2.andro_banner .andro_banner-slider-text h1{
    font-size: 34px;
  }
  .section-title.flex-title .nav{
    width: 100%;
    margin: 20px 0 0;
  }
  .modal-body{
    padding: 20px;
  }
  .andro_product-atc-form .qty-outter,
  .andro_product-atc-form .qty-outter .andro_btn-custom{
    display: block;
  }
  .andro_product-atc-form .qty-outter .andro_btn-custom + .qty{
    margin-left: 0;
    margin-top: 20px;
  }
  .qty input{
    width: 100%;
  }
  .andro_product.andro_product-list{
    display: block;
  }
  .andro_product.andro_product-list .andro_product-thumb{
    width: auto;
    margin-right: 0;
  }
  .andro_cta-notice{
    padding: 20px;
  }
  .andro_product.andro_product-has-buttons .andro_product-footer .andro_product-buttons a{
    flex: 0 100%;
    width: 100%;
  }
  .andro_product.andro_product-has-buttons .andro_product-footer .andro_product-buttons a:first-child{
    border-radius: 0;
  }
  .andro_product.andro_product-has-buttons .andro_product-footer .andro_product-buttons a + a{
    border-radius: 0 0 8px 8px;
  }
  .andro_header-cart-content,
  .andro_header-controls-inner li.andro_header-favorites,
  .andro_current-currency-text{
    display: none;
  }
  .andro_header-controls-inner li.andro_header-cart a i{
    font-size: 21px;
  }
  .andro_header-middle .navbar{
    padding: 10px 0;
  }
  .andro_shop-global{
    display: block;
  }
  .andro_shop-global p{
    margin-bottom: 20px;
  }
  .andro_product-additional-info .tab-content{
    padding: 20px;
  }
  .comments-list .comment-item span{
    position: relative;
    top: auto;
    right: auto;
    display: block;
  }
  .comments-list .comment-item img{
    width: 45px;
    margin-right: 15px;
  }
  .andro_post-single-meta{
    display: block;
  }
  .andro_post-single-meta-item + .andro_post-single-meta-item{
    margin-top: 20px;
  }
  .andro_auth-form{
    padding: 30px;
  }
  .andro_cta-content p{
    margin-bottom: 10px;
    max-width: 100%;
  }

  .img-zoom-container .outerImg{width:100% !important;}
  .img-zoom-container .outerImg img{width:100% !important; transition:.5s ease;}
  .img-zoom-container .outerImg .img-zoom-lens{display:none !important;}
  .imageViewer{margin-bottom:1rem;}

}

.modal-open {
  overflow: hidden;
  position:fixed;
  width: 100%;
  height: 100%;
}
